<template>
  <!--Page Header-->
  <section class="page-header contactus_page">
    <div class="container">
      <div class="page-header_wrap">
        <div class="page-heading">
          <h1>{{ pageTitle }}</h1>
        </div>
        <ul v-if="pageTitle !== 'Page Not Found'" class="coustom-breadcrumb">
          <li><router-link to="/">Home</router-link></li>
          <li v-for="(c, index) in crumbs" :key="index">
            <router-link :to="c.route">{{ c.title }}</router-link>
          </li>
          <li>{{ pageTitle }}</li>
        </ul>
      </div>
    </div>
    <!-- Dark Overlay-->
    <div class="dark-overlay"></div>
  </section>
  <!-- /Page Header--> 
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    crumbs: {
      default: () => ([]),
      type: Array
    },
    pageTitle: {
      default: () => (''),
      type: String
    }
  }
}
</script>
